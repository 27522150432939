/* eslint-disable @angular-eslint/no-host-metadata-property */
import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

export const ICON_MESH = `
<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <flx-shine *ngIf="shine"></flx-shine>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M15.311 21.4467C16.5 21.0277 17.585 20.3897 18.52 19.5817C19.032 19.1397 18.929 18.3137 18.344
    17.9747L15.503 16.3347C14.836 15.9497 14.002 16.4307 14.002 17.2017V20.4917C14.002 21.1707 14.67
    21.6727 15.311 21.4467Z"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M8.69 2.55489C7.5 2.97289 6.415 3.61189 5.48 4.41989C4.968 4.86189 5.071 5.68789 5.657 6.02589L8.498
    7.66589C9.164 8.05189 9.998 7.56989 9.998 6.79989V3.50989C9.998 2.83089 9.33 2.32889 8.69 2.55489Z"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M21.835 13.8573C22.067 12.6183 22.056 11.3593 21.824 10.1453C21.697 9.48033 20.93 9.15633 20.344
    9.49533L17.503 11.1353C16.836 11.5203 16.836 12.4833 17.503 12.8683L20.352 14.5133C20.941 14.8523 21.71
    14.5243 21.835 13.8573Z"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M2.165 10.1427C1.933 11.3817 1.944 12.6407 2.176 13.8547C2.303 14.5197 3.07 14.8437 3.656
    14.5047L6.497 12.8647C7.164 12.4797 7.164 11.5167 6.497 11.1317L3.647 9.48675C3.059 9.14775 2.29
    9.47575 2.165 10.1427Z"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M18.525 4.41195C17.568 3.59095 16.472 2.97095 15.305 2.56495C14.666 2.34295 14.002 2.84495
    14.002 3.52095V6.80095C14.002 7.57095 14.836 8.05295 15.503 7.66795L18.352 6.02295C18.94 5.68395
    19.04 4.85395 18.525 4.41195V4.41195Z"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M5.475 19.5906C6.432 20.4116 7.528 21.0316 8.695 21.4376C9.334 21.6596 9.998 21.1576 9.998
    20.4816V17.2016C9.998 16.4316 9.164 15.9496 8.497 16.3346L5.648 17.9796C5.06 18.3186 4.96 19.1486
    5.475 19.5906V19.5906Z"
  />
</svg>
`;

@Component({
  selector: 'flx-icon-mesh',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { class: 'flx-icon extra-thin' },
  template: ICON_MESH,
  styleUrls: ['./icons.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconMeshComponent {
  @HostBinding('class.shine')
  @Input()
  shine = false;
}
