/* eslint-disable @angular-eslint/no-host-metadata-property */
import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

export const ICON_CAR = `
<svg viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
  <flx-shine *ngIf="shine"></flx-shine>
  <path d="M21.7583 25.9287H11.6714" />
  <circle cx="23.912" cy="24.9086" r="2.38902" />
  <circle cx="9.28209" cy="24.9086" r="2.38902" />
  <path
    d="M26.0661 25.9307H27.7542C28.7634 25.9296 29.6207 25.1921 29.7725 24.1944L30.2301 21.2116C30.4527 19.8166 29.5725 18.4828 28.2024 18.1389C26.1546 17.6519 24.0518 17.4362 21.9479 17.4971C20.7232 16.426 19.3681 15.5138 17.9148 14.7824C14.8899 13.2653 11.3263 13.2653 8.30138 14.7824L5.22841 16.3231C4.79548 16.5375 4.34603 16.7169 3.8844 16.8594C3.32827 17.0413 2.95395 17.5624 2.95904 18.1475V23.8831C2.95834 24.4255 3.17306 24.9459 3.55603 25.3299C3.93899 25.714 4.45883 25.9301 5.00117 25.9309H7.12727"
  />
</svg>
`;

@Component({
  selector: 'flx-icon-car',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { class: 'flx-icon thin' },
  template: ICON_CAR,
  styleUrls: ['./icons.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconCarComponent {
  @HostBinding('class.shine')
  @Input()
  shine = false;
}
