/* eslint-disable @angular-eslint/no-host-metadata-property */
import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

export const ICON_USER = `
<svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    class="outline"
    d="M25.3333 28H6.66667C5.19333 28 4 26.8067 4 25.3333V6.66667C4 5.19333 5.19333 4 6.66667
    4H25.3333C26.8067 4 28 5.19333 28 6.66667V25.3333C28 26.8067 26.8067 28 25.3333 28Z"
  />
  <path
    d="M19.3005 9.92934C21.1229 11.7518 21.1229 14.7066 19.3005 16.529C17.478 18.3515 14.5233
    18.3515 12.7008 16.529C10.8784 14.7066 10.8784 11.7518 12.7008 9.92934C14.5233 8.10689
    17.478 8.10689 19.3005 9.92934" stroke="none" fill="var(--color)"
  />
  <path
    d="M23.2172 24.04C22.9812 23.4454 22.6146 22.912 22.1453 22.4774V22.4774C21.3519 21.7414
    20.3146 21.332 19.2319 21.332C17.4266 21.332 14.5733 21.332 12.7679 21.332C11.6853 21.332
    10.6479 21.7414 9.85325 22.4774V22.4774C9.38392 22.912 9.01858 23.4454 8.78125 24.04"
    fill="var(--color)"
  />
</svg>
`;

@Component({
  selector: 'flx-user',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { class: 'flx-icon thin' },
  template: ICON_USER,
  styleUrls: ['./icons.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconUserComponent {
  @HostBinding('class.selected')
  @Input()
  selected = false;
}
