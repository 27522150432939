/* eslint-disable @angular-eslint/no-host-metadata-property */
import { ChangeDetectionStrategy, Component } from '@angular/core';

export const ICON_FLIGHT = `
<svg viewBox="0 0 33 34" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M13.8806 18.2383L9.00351 26.7731C8.88279 26.9844 8.88366 27.2439 9.00578 27.4544C9.12791 27.6648 9.35283 27.7944 9.59615 27.7944H11.8959C12.2896 27.7944 12.6641 27.6244 12.9233 27.3282L20.877 18.2383"
  />
  <path
    d="M12.3205 20.972H9.33012C7.85323 20.972 6.41617 20.493 5.23466 19.6069L1.59424 16.8766L2.95939 15.5114L1.59424 10.0508H5.6897L8.42001 14.1462H27.5322C29.7941 14.1462 31.6277 15.9798 31.6277 18.2417V18.2417C31.6277 19.7496 30.4053 20.972 28.8973 20.972H18.488"
  />
  <path
    d="M20.8768 14.1401L14.1177 6.41549C13.8584 6.11918 13.4839 5.94922 13.0902 5.94922H10.7904C10.5471 5.94922 10.3222 6.07877 10.2001 6.28921C10.078 6.49965 10.0771 6.75917 10.1978 6.97042L14.2948 14.1401"
  />
</svg>
`;

@Component({
  selector: 'flx-icon-flight',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { class: 'flx-icon thin' },
  template: ICON_FLIGHT,
  styleUrls: ['./icons.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconFlightComponent {}
