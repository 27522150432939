/* eslint-disable @angular-eslint/no-host-metadata-property */
import { ChangeDetectionStrategy, Component } from '@angular/core';

export const ICON_CHEVRON_RIGHT = `
<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M10 16L14 12L10 8" />
</svg>
`;

@Component({
  selector: 'flx-icon-chevron-right',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { class: 'flx-icon' },
  template: ICON_CHEVRON_RIGHT,
  styles: [
    `
      svg {
        stroke: currentColor;
        stroke-width: 1.5;
        stroke-linecap: 'round';
        stroke-linejoin: 'round';
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconChevronRightComponent {}
