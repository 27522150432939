/* eslint-disable @angular-eslint/no-host-metadata-property */
import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

export const ICON_OFFICE = `
<svg viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
  <flx-shine *ngIf="shine"></flx-shine>
  <circle cx="23.9997" cy="23.9997" r="18.0075" />
  <path d="M24.0004 12.9961V17.9982" />
  <path
    d="M18.998 24.0025V20.0008C18.998 18.8958 19.8939 18 20.9989 18H27.0014C28.1064 18 29.0022 18.8958 29.0022 20.0008V24.0025"
  />
  <path
    d="M13.9961 38.9722V26.0008C13.9961 24.8958 14.8919 24 15.9969 24H32.0036C33.1086 24 34.0044 24.8958 34.0044 26.0008V38.9722"
  />
  <path d="M18.998 30.5043H29.0022" />
  <path d="M18.998 35.5043H29.0022" />
</svg>
`;
@Component({
  selector: 'flx-icon-office',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { class: 'flx-icon' },
  template: ICON_OFFICE,
  styleUrls: ['./icons.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconOfficeComponent {
  @HostBinding('class.shine')
  @Input()
  shine = false;
}
