import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'flx-shine',
  encapsulation: ViewEncapsulation.None,
  template: `
    <svg>
      <defs #def>
        <linearGradient
          id="shine-gradient_{{ myId }}"
          x1="0%"
          y1="0%"
          x2="100%"
          y2="45%"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0%" class="top-color" />
          <stop offset="100%" class="bottom-color" />
        </linearGradient>
      </defs>
    </svg>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShineComponent implements OnInit, AfterViewInit {
  static id = 0;

  myId = 0;

  @ViewChild('def') def!: ElementRef<SVGDefsElement>;
  constructor(private el: ElementRef) {}

  ngOnInit(): void {
    this.myId = +`${ShineComponent.id++}`;
    this.el.nativeElement.parentElement.style.setProperty('--shineUrl', `url(#shine-gradient_${this.myId})`);
  }

  ngAfterViewInit() {
    const nativeElement: HTMLElement = this.el.nativeElement;
    const parentElement: HTMLElement = nativeElement.parentElement as HTMLElement;
    // move all children out of the element
    parentElement.insertBefore(this.def.nativeElement, nativeElement);
    // remove the empty element(the host)
    parentElement.removeChild(nativeElement);
  }
}
