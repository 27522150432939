import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

export const ICON_MEETING = `
<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <flx-shine *ngIf="shine"></flx-shine>
  <path d="M15.2156 10.4282C17.0019 10.3632 18.4197 8.90226 18.4312 7.11482C18.4036 5.36614 16.9643 3.97058 15.2156 3.99695"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M9.42753 10.4282C11.2138 10.3632 12.6317 8.90226 12.6432 7.11482C12.6156 5.36614 11.1762 3.97058 9.42753 3.99695C7.67884 3.97058 6.23952 5.36614 6.21191 7.11482C6.22292 8.90244 7.64104 10.3637 9.42753 10.4282V10.4282Z"/>
  <path d="M16.0016 20.0035V18.0026C16.0016 15.7926 14.21 14.001 12 14.001H6.99788C4.78782 14.001 2.99622 15.7926 2.99622 18.0026V20.0035"/>
  <path d="M21.0037 20.0035V17.0022C21.0037 15.3447 19.66 14.001 18.0024 14.001"/>
</svg>
`;
@Component({
  selector: 'flx-icon-meeting',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { class: 'flx-icon' },
  template: ICON_MEETING,
  styles: [
    `
      :host {
        svg path {
          fill: none;
          stroke-width: 1.5;
          stroke: currentColor;
        }
        &.shine svg path {
          stroke: var(--shineUrl);
        }
      }
    `,
  ],
  styleUrls: ['./icons.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconMeetingComponent {
  @HostBinding('class.shine')
  @Input()
  shine = false;
}
