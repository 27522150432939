/* eslint-disable @angular-eslint/no-host-metadata-property */
import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

export const ICON_FLEX = `
<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <flx-shine *ngIf="shine"></flx-shine>
  <path d="M11 19.392V7.503C11 6.813 10.645 6.172 10.06 5.807L6.06 3.307C4.728 2.475 3 3.432 3 5.003V16.891C3 17.581 3.355 18.222 3.94 18.587L7.94 21.087C9.272 21.92 11 20.962 11 19.392Z"/>
  <path d="M15 11H21"/>
  <path d="M19 13L21 11L19 9"/>
  <path d="M11 19H15C16.105 19 17 18.105 17 17V16"/>
  <path d="M17 6V5C17 3.895 16.105 3 15 3H5"/>
</svg>
`;

@Component({
  selector: 'flx-icon-flex',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { class: 'flx-icon thin' },
  template: ICON_FLEX,
  styleUrls: ['./icons.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconFlexComponent {
  @HostBinding('class.shine')
  @Input()
  shine = false;
}
