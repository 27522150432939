import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ICON_AUTOBOOKING, IconAutoBookingComponent } from './autobooking.component';
import { ICON_BICYCLE, IconBicycleComponent } from './bicycle.component';
import { ICON_BUS, IconBusComponent } from './bus.component';
import { ICON_CAR, IconCarComponent } from './car.component';
import { ICON_CHECKMARK, IconCheckmarkComponent } from './checkmark.component';
import { ICON_CHEVRON_LEFT, IconChevronLeftComponent } from './chevron-left.component';
import { ICON_CHEVRON_RIGHT, IconChevronRightComponent } from './chevron-right.component';
import { ICON_CLOSE, IconCloseComponent } from './close.component';
import { ICON_SOCIAL, IconColleaguesComponent } from './colleagues-icon.component';
import { ICON_ELECTRIC_CAR, IconElectricCarComponent } from './electric-car.component';
import { ICON_FAVORITE, IconFavoriteComponent } from './favorite.component';
import { ICON_FIND_LOCKER } from './find-locker.component';
import { ICON_FLEX, IconFlexComponent } from './flex.component';
import { ICON_FLIGHT, IconFlightComponent } from './flight.component';
import { ICON_GEAR, IconGearComponent } from './gear.component';
import { ICON_HOME, IconHomeComponent } from './home.component';
import { ICON_HOUSE, IconHouseComponent } from './house.component';
import { ICON_CHEF, IconChefComponent } from './icon-chef.component';
import { ICON_ECO, IconEcoComponent } from './icon-eco.component';
import { ICON_LOCATION, IconLocationComponent } from './icon-location.component';
import { ICON_LOCKER, IconLockerComponent } from './icon-locker.component';
import { ICON_MEETING, IconMeetingComponent } from './icon-meeting.component';
import { ICON_PARKING, IconParkingComponent } from './icon-parking.component';
import { ICON_TEAM, IconTeamComponent } from './icon-team.component';
import { ICON_TIME, IconTimeComponent } from './icon-time.component';
import { ICON_MESH, IconMeshComponent } from './mesh.component';
import { ICON_OFFICE, IconOfficeComponent } from './office.component';
import { ICON_PLUS, IconPlusComponent } from './plus.component';
import { ICON_SEARCH, IconSearchComponent } from './search.component';
import { ShineComponent } from './shine.component';
import { ICON_SMILEY, IconSmileyComponent } from './smiley.component';
import { ICON_STAR, IconStarComponent } from './star.component';
import { ICON_STATISTICS, IconStatisticsComponent } from './statistics.component';
import { ICON_TARGET, IconTargetComponent } from './target.component';
import { TransportationTypeComponent } from './transportation-type.component';
import { ICON_UNAVAILABLE, IconUnavailableComponent } from './unavailable.component';
import { ICON_USER, IconUserComponent } from './user.component';
import { ICON_WALK, IconWalkComponent } from './walk.component';
import { WorkplaceIconComponent } from './workplace-icon.component';

@NgModule({
  declarations: [
    WorkplaceIconComponent,
    ShineComponent,
    IconAutoBookingComponent,
    IconOfficeComponent,
    IconHouseComponent,
    IconHomeComponent,
    IconFlexComponent,
    IconUnavailableComponent,
    IconStatisticsComponent,
    IconUserComponent,
    IconColleaguesComponent,
    IconStarComponent,
    IconGearComponent,
    IconPlusComponent,
    IconMeshComponent,
    IconTargetComponent,
    IconFavoriteComponent,
    IconSmileyComponent,
    IconSearchComponent,
    IconCloseComponent,
    IconCheckmarkComponent,
    IconChevronLeftComponent,
    IconChevronRightComponent,
    IconCarComponent,
    IconBusComponent,
    IconBicycleComponent,
    IconWalkComponent,
    IconElectricCarComponent,
    IconFlightComponent,
    TransportationTypeComponent,
    IconTimeComponent,
    IconTeamComponent,
    IconEcoComponent,
    IconLocationComponent,
    IconLockerComponent,
    IconMeetingComponent,
    IconChefComponent,
    IconParkingComponent,
  ],
  imports: [CommonModule, MatIconModule],
  exports: [
    WorkplaceIconComponent,
    IconOfficeComponent,
    IconAutoBookingComponent,
    IconHouseComponent,
    IconHomeComponent,
    IconFlexComponent,
    IconUnavailableComponent,
    IconStatisticsComponent,
    IconUserComponent,
    IconColleaguesComponent,
    IconStarComponent,
    IconGearComponent,
    IconPlusComponent,
    IconMeshComponent,
    IconTargetComponent,
    IconFavoriteComponent,
    IconSmileyComponent,
    IconSearchComponent,
    IconCloseComponent,
    IconCheckmarkComponent,
    IconChevronLeftComponent,
    IconChevronRightComponent,
    IconCarComponent,
    IconBusComponent,
    IconBicycleComponent,
    IconWalkComponent,
    IconElectricCarComponent,
    IconFlightComponent,
    TransportationTypeComponent,
    IconTimeComponent,
    IconTeamComponent,
    IconEcoComponent,
    IconLocationComponent,
    IconLockerComponent,
    IconMeetingComponent,
    IconChefComponent,
    IconParkingComponent,
  ],
})
export class FLXIconsModule {
  constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    iconRegistry.addSvgIconLiteral('home', sanitizer.bypassSecurityTrustHtml(ICON_HOME));
    iconRegistry.addSvgIconLiteral('flex', sanitizer.bypassSecurityTrustHtml(ICON_FLEX));
    iconRegistry.addSvgIconLiteral('unavailable', sanitizer.bypassSecurityTrustHtml(ICON_UNAVAILABLE));
    iconRegistry.addSvgIconLiteral('statistics', sanitizer.bypassSecurityTrustHtml(ICON_STATISTICS));
    iconRegistry.addSvgIconLiteral('social', sanitizer.bypassSecurityTrustHtml(ICON_SOCIAL));
    iconRegistry.addSvgIconLiteral('user', sanitizer.bypassSecurityTrustHtml(ICON_USER));

    // Functional icons
    iconRegistry.addSvgIconLiteral('chevron-left', sanitizer.bypassSecurityTrustHtml(ICON_CHEVRON_LEFT));
    iconRegistry.addSvgIconLiteral('chevron-right', sanitizer.bypassSecurityTrustHtml(ICON_CHEVRON_RIGHT));
    iconRegistry.addSvgIconLiteral('close', sanitizer.bypassSecurityTrustHtml(ICON_CLOSE));
    iconRegistry.addSvgIconLiteral('gear', sanitizer.bypassSecurityTrustHtml(ICON_GEAR));
    iconRegistry.addSvgIconLiteral('plus', sanitizer.bypassSecurityTrustHtml(ICON_PLUS));
    iconRegistry.addSvgIconLiteral('search', sanitizer.bypassSecurityTrustHtml(ICON_SEARCH));

    iconRegistry.addSvgIconLiteral('favorite', sanitizer.bypassSecurityTrustHtml(ICON_FAVORITE));
    iconRegistry.addSvgIconLiteral('star', sanitizer.bypassSecurityTrustHtml(ICON_STAR));
    iconRegistry.addSvgIconLiteral('locker', sanitizer.bypassSecurityTrustHtml(ICON_LOCKER));
    iconRegistry.addSvgIconLiteral('meeting', sanitizer.bypassSecurityTrustHtml(ICON_MEETING));
    iconRegistry.addSvgIconLiteral('lunch', sanitizer.bypassSecurityTrustHtml(ICON_CHEF));
    iconRegistry.addSvgIconLiteral('parking', sanitizer.bypassSecurityTrustHtml(ICON_PARKING));

    // Asset type icons (These all have a "shine" option when used as components)
    iconRegistry.addSvgIconLiteral('house', sanitizer.bypassSecurityTrustHtml(ICON_HOUSE));
    iconRegistry.addSvgIconLiteral('mesh', sanitizer.bypassSecurityTrustHtml(ICON_MESH));
    iconRegistry.addSvgIconLiteral('autoBooking', sanitizer.bypassSecurityTrustHtml(ICON_AUTOBOOKING));
    iconRegistry.addSvgIconLiteral('office', sanitizer.bypassSecurityTrustHtml(ICON_OFFICE));
    iconRegistry.addSvgIconLiteral('target', sanitizer.bypassSecurityTrustHtml(ICON_TARGET));

    // Statistics icons
    iconRegistry.addSvgIconLiteral('eco', sanitizer.bypassSecurityTrustHtml(ICON_ECO));
    iconRegistry.addSvgIconLiteral('location', sanitizer.bypassSecurityTrustHtml(ICON_LOCATION));
    iconRegistry.addSvgIconLiteral('time', sanitizer.bypassSecurityTrustHtml(ICON_TIME));
    iconRegistry.addSvgIconLiteral('team', sanitizer.bypassSecurityTrustHtml(ICON_TEAM));

    // Transportation icons
    iconRegistry.addSvgIconLiteral('walk', sanitizer.bypassSecurityTrustHtml(ICON_WALK));
    iconRegistry.addSvgIconLiteral('bicycle', sanitizer.bypassSecurityTrustHtml(ICON_BICYCLE));
    iconRegistry.addSvgIconLiteral('bus', sanitizer.bypassSecurityTrustHtml(ICON_BUS));
    iconRegistry.addSvgIconLiteral('car', sanitizer.bypassSecurityTrustHtml(ICON_CAR));
    iconRegistry.addSvgIconLiteral('electric-car', sanitizer.bypassSecurityTrustHtml(ICON_ELECTRIC_CAR));
    iconRegistry.addSvgIconLiteral('flight', sanitizer.bypassSecurityTrustHtml(ICON_FLIGHT));

    iconRegistry.addSvgIconLiteral('checkmark', sanitizer.bypassSecurityTrustHtml(ICON_CHECKMARK));
    iconRegistry.addSvgIconLiteral('smiley', sanitizer.bypassSecurityTrustHtml(ICON_SMILEY));
    iconRegistry.addSvgIconLiteral('find_locker', sanitizer.bypassSecurityTrustHtml(ICON_FIND_LOCKER));
  }
}
