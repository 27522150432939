/* eslint-disable @angular-eslint/no-host-metadata-property */
import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

export const ICON_STATISTICS = `
<svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M22.6667 28H9.33333C6.388 28 4 25.612 4 22.6667V9.33333C4 6.388 6.388 4 9.33333 4H22.6667C25.612 4 28 6.388 28 9.33333V22.6667C28 25.612 25.612 28 22.6667 28Z"
  />
  <path
    d="M13.3333 13.332L4 19.9987V22.5C4 26.1 7.83333 27.8333 9.5 28H23C26.6 28 27.8333 24.6667 28 23V12L18.6667 18.6654L13.3333 13.332Z"
  />
  <path
    class="outline"
    d="M 23.253468,27.981404 H 9.3147403 c -2.3272922,0 -5.3410395,-2.758739 -5.3410395,-5.089358 V 9.0367289 c 0,-2.2352448 2.4500216,-5.0181325 4.8122505,-5.0181325 H 23.277511 c 2.425781,0 4.703893,2.8146338 4.703893,4.9623433 V 22.785915 c 0,2.362288 -2.238663,5.195489 -4.727936,5.195489 z"
  />
</svg>
`;

@Component({
  selector: 'flx-icon-statistics',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { class: 'flx-icon thin' },
  template: ICON_STATISTICS,
  styles: [
    `
      :host {
        &.selected .outline {
          fill: none !important;
          stroke-width: 2.2;
          stroke: var(--text) !important;
        }
        &:not(.selected) .outline {
          display: none;
        }
      }
    `,
  ],
  styleUrls: ['./icons.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconStatisticsComponent {
  @HostBinding('class.selected')
  @Input()
  selected = false;
}
