/* eslint-disable @angular-eslint/no-host-metadata-property */
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

export const ICON_TEAM = `
<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M13.5557 15.8591C13.143 16.2716 12.5834 16.5034 12 16.5034V16.5034C11.4166 16.5034 10.857 16.2716 10.4444 15.8591L7.80826 13.223C6.72781 12.1426 6.72781 10.3908 7.80826 9.3104C8.8887 8.22995 10.6404 8.22995 11.7209 9.3104L12 9.58951L12.2791 9.3104C12.978 8.61147 13.9967 8.33851 14.9515 8.59433C15.9062 8.85016 16.652 9.5959 16.9078 10.5506C17.1636 11.5054 16.8907 12.5241 16.1917 13.223L13.5557 15.8591Z"/>
  <circle cx="12" cy="11.9998" r="9.00375"/>
</svg>
`;

@Component({
  selector: 'flx-icon-team',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { class: 'flx-icon thin' },
  template: ICON_TEAM,
  styleUrls: ['./icons.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconTeamComponent {}
