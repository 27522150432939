/* eslint-disable @angular-eslint/no-host-metadata-property */
import { ChangeDetectionStrategy, Component } from '@angular/core';

export const ICON_BUS = `
<svg viewBox="0 0 33 34" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M9.51953 25.9677H21.153" />
  <circle cx="23.2011" cy="25.997" r="2.04773" />
  <circle cx="7.47156" cy="25.997" r="2.04773" />
  <path d="M2.72363 17.124H29.7384" />
  <path d="M20.4702 17.1245V8.93359" />
  <path d="M10.9145 17.1245V8.93359" />
  <path d="M30.0267 21.2216H27.2964" />
  <path
    d="M5.42426 25.998H3.91073C3.25511 25.998 2.72363 25.4666 2.72363 24.8109V11.3078C2.72363 9.99655 3.78659 8.93359 5.09782 8.93359H24.2628C26.0649 8.93359 27.6553 10.1116 28.1805 11.8356L29.849 17.3126C29.9668 17.6995 30.0267 18.1016 30.0267 18.5061V23.238C30.0267 24.7459 28.8043 25.9683 27.2964 25.9683H25.2487"
  />
</svg>
`;

@Component({
  selector: 'flx-icon-bus',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { class: 'flx-icon thin' },
  template: ICON_BUS,
  styleUrls: ['./icons.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconBusComponent {}
