/* eslint-disable @angular-eslint/no-host-metadata-property */
import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

export const ICON_SEARCH = `
<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M11.0581 18.217C14.9583 18.217 18.12 15.0388 18.12 11.1183C18.12 7.19774 14.9583 4.01953 11.0581
    4.01953C7.15789 4.01953 3.99615 7.19774 3.99615 11.1183C3.99615 15.0388 7.15789 18.217 11.0581 18.217Z"
  />
  <path d="M20.0024 20.1089L16.0508 16.1367" />
</svg>
`;

@Component({
  selector: 'flx-icon-search',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { class: 'flx-icon extra-thin' },
  template: ICON_SEARCH,
  styleUrls: ['./icons.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconSearchComponent {
  @HostBinding('class.shine')
  @Input()
  shine = false;
}
