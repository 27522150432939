/* eslint-disable @angular-eslint/no-host-metadata-property */
import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

export const ICON_HOME = `
<svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    class="outline"
    d="M4.97867 13.0187L16.0027 4L27.0227 13.0187C27.6413 13.524 28 14.2827 28 15.0813V25.3333C28
    26.8067 26.8067 28 25.3333 28H6.66667C5.19333 28 4 26.8067 4 25.3333V15.0827C4 14.2827 4.35867
    13.524 4.97867 13.0187Z"
  />
</svg>
`;

@Component({
  selector: 'flx-icon-home',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { class: 'flx-icon thin' },
  template: ICON_HOME,
  styleUrls: ['./icons.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconHomeComponent {
  @HostBinding('class.selected')
  @Input()
  selected = false;
}
