import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

export const ICON_CHEF = `
<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <flx-shine *ngIf="shine"></flx-shine>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5 22V22C6.977 22 2.5 17.523 2.5 12V12C2.5 6.477 6.977 2 12.5 2V2C18.023 2 22.5 6.477 22.5 12V12C22.5 17.523 18.023 22 12.5 22Z"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M10.5994 7.42C10.9544 6.728 11.6674 6.25 12.4984 6.25C13.3294 6.25 14.0424 6.728 14.3974 7.42C14.8854 7.126 15.4914 7.005 16.1424 7.206C16.8594 7.428 17.4324 8.04 17.5904 8.774C17.8564 10.012 17.0514 11.121 15.9274 11.349V14.821C15.9274 16.715 14.3924 18.25 12.4984 18.25C10.6044 18.25 9.0694 16.715 9.0694 14.821V11.955C9.0694 11.616 8.8624 11.324 8.5584 11.175C7.7154 10.763 7.1834 9.814 7.4064 8.774C7.5644 8.041 8.1374 7.428 8.8544 7.206C9.5054 7.005 10.1104 7.126 10.5994 7.42Z"/>
  <path d="M15.9303 13.1094H9.07031"/>
</svg>

`;
@Component({
  selector: 'flx-icon-chef',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { class: 'flx-icon' },
  template: ICON_CHEF,
  styles: [
    `
      :host {
        svg path {
          fill: none;
          stroke-width: 1.5;
          stroke: currentColor;
        }
        &.shine svg path {
          stroke: var(--shineUrl);
        }
      }
    `,
  ],
  styleUrls: ['./icons.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconChefComponent {
  @HostBinding('class.shine')
  @Input()
  shine = false;
}
