/* eslint-disable @angular-eslint/no-host-metadata-property */
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

export const ICON_LOCATION = `
<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M9.37 22H14.59" stroke="#393940" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
  <path d="M11.98 22.0019V19.6719" />
  <circle cx="10.935" cy="10.8659" r="5.737" />
  <path
    d="M11.078 2.00781C15.908 2.08481 19.802 6.01881 19.802 10.8678C19.802 15.7648 15.832 19.7348 10.935 19.7348C9.60895 19.7348 8.35595 19.4358 7.22595 18.9138"
  />
</svg>
`;

@Component({
  selector: 'flx-icon-location',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { class: 'flx-icon thin' },
  template: ICON_LOCATION,
  styleUrls: ['./icons.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconLocationComponent {}
