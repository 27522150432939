import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'flx-transportation-type',
  template: `
    @switch (type) {
      @case ('Car') {
        <flx-icon-car></flx-icon-car>
      }
      @case ('Walk') {
        <flx-icon-walk></flx-icon-walk>
      }
      @case ('Flight') {
        <flx-icon-flight></flx-icon-flight>
      }
      @case ('Public Transport') {
        <flx-icon-bus></flx-icon-bus>
      }
      @case ('Bicycle') {
        <flx-icon-bicycle></flx-icon-bicycle>
      }
      @case ('Electric car') {
        <flx-icon-electric-car></flx-icon-electric-car>
      }
    }
  `,
  styles: [
    `
      :host {
        display: grid;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransportationTypeComponent {
  @Input() type = 'car';
}
