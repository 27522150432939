/* eslint-disable @angular-eslint/no-host-metadata-property */
import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

export const ICON_SOCIAL = `
<svg viewBox="0 0 32 32" fill="none"
  xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" class="outline" d="M25.3333 28H6.66667C5.19333 28 4 26.8067 4 25.3333V6.66667C4 5.19333 5.19333 4 6.66667   4H25.3333C26.8067 4 28 5.19333 28 6.66667V25.3333C28 26.8067 26.8067 28 25.3333 28Z" />
  <path d="m 13.19879,18.064113 c -2.199996,0 -6.5811781,1.099998 -6.5811781,3.290591 V 23 H 19.779972 v -1.645296 c 0,-2.190593 -4.381187,-3.290591 -6.581182,-3.290591 z m 0,-1.645295 c 1.814526,0 3.290591,-1.476065 3.290591,-3.290591 0,-1.814526 -1.476065,-3.2905913 -3.290591,-3.2905913 -1.814526,0 -3.2905901,1.4760653 -3.2905901,3.2905913 0,1.814526 1.4760641,3.290591 3.2905901,3.290591 z m 6.618788,1.701706 c 1.090596,0.789741 1.842731,1.842731 1.842731,3.23418 V 23 h 3.760676 v -1.645296 c 0,-1.899141 -3.290591,-2.980335 -5.603407,-3.23418 z m -0.977775,-1.701706 c 1.814526,0 3.290591,-1.476065 3.290591,-3.290591 0,-1.814526 -1.476065,-3.2905913 -3.290591,-3.2905913 -0.507691,0 -0.977776,0.122222 -1.410253,0.3290593 0.592306,0.83675 0.940168,1.861534 0.940168,2.961532 0,1.099997 -0.347862,2.124781 -0.940168,2.961532 0.432477,0.206837 0.902562,0.329059 1.410253,0.329059 z" stroke="none" fill="var(--color)" />
</svg>
`;

@Component({
  selector: 'flx-icon-colleagues',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { class: 'flx-icon thin' },
  template: ICON_SOCIAL,
  styleUrls: ['./icons.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconColleaguesComponent {
  @HostBinding('class.selected')
  @Input()
  selected = false;
}
